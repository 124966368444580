export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Home",
      authRequired: false,
    },
    component: () => import("../views/home/Index.vue"),
  },
  {
    path: "/profiles",
    name: "profiles",
    meta: {
      title: "Profiles",
      authRequired: false,
    },
    component: () => import("../views/profiles/Profiles.vue"),
  },
  {
    path: "/profile/:login",
    name: "profile",
    meta: {
      title: "Profiles",
      authRequired: false,
    },
    component: () => import("../views/profile/Profile.vue"),
  },
  {
    path: "/podcasts",
    name: "podcasts",
    meta: {
      title: "Podcasts",
      authRequired: false,
    },
    component: () => import("../views/podcasts/Podcasts.vue"),
  },
  {
    path: "/podcasts/category/:id",
    name: "podcasts-categories",
    meta: {
      title: "Podcasts",
      authRequired: false,
    },
    component: () => import("../views/podcasts/Podcasts.vue"),
  },
  {
    path: "/podcasts/tag/:value",
    name: "podcasts-tag",
    meta: {
      title: "Podcasts tag",
      authRequired: false,
    },
    component: () => import("../views/podcasts/PodcastsTag.vue"),
  },
  {
    path: "/podcast/:name",
    name: "podcast",
    meta: {
      title: "Podcast",
      authRequired: false,
    },
    component: () => import("../views/podcast/Podcast.vue"),
  },
  {
    path: "/episodes",
    name: "episodes",
    meta: {
      title: "Episodes",
      authRequired: false,
    },
    component: () => import("../views/episodes/Episodes.vue"),
  },
  {
    path: "/episodes/tag/:value",
    name: "episodes-tag",
    meta: {
      title: "Episodes tag",
      authRequired: false,
    },
    component: () => import("../views/episodes/EpisodesTag.vue"),
  },
  {
    path: "/episode/:id",
    name: "episode",
    meta: {
      title: "Episode",
      authRequired: false,
    },
    component: () => import("../views/episode/Episode.vue"),
  },
  {
    path: "/post/:id",
    name: "post",
    meta: {
      title: "Post",
      authRequired: false,
    },
    component: () => import("../views/post/Post.vue"),
  },
  {
    path: "/blog/:blogLink",
    name: "blog",
    meta: {
      title: "Blog",
      authRequired: false,
    },
    component: () => import("../views/blog/Blog.vue"),
  },
  {
    path: "/blog/:blogLink/:articleLink",
    name: "article",
    meta: {
      title: "Article",
      authRequired: false,
    },
    component: () => import("../views/article/Article.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    meta: {
      title: "FAQ",
      authRequired: false,
    },
    component: () => import("../views/faq/Faq.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    meta: {
      title: "Contacts Title",
      authRequired: false,
    },
    component: () => import("../views/contacts/Contacts.vue"),
  },

  //Pages
  {
    path: "/pages/terms-conditions",
    name: "terms-conditions",
    meta: {
      title: "Terms & Conditions",
      authRequired: false,
    },
    component: () => import("../views/pages/TermsConditions.vue"),
  },
  {
    path: "/pages/privacy-policy",
    name: "privacy-policy",
    meta: {
      title: "Privacy policy",
      authRequired: false,
    },
    component: () => import("../views/pages/PrivacyPolicy.vue"),
  },

  //Search
  {
    path: "/search",
    name: "search",
    meta: {
      title: "Search",
      authRequired: true,
    },
    component: () => import("../views/search/Search.vue"),
  },

  //Live
  {
    path: "/payments/donation/:id/:session",
    name: "donation",
    meta: {
      title: "Donation",
      authRequired: false,
    },
    component: () => import("../views/payments/Donation.vue"),
  },
  {
    path: "/payments/success/:id?",
    name: "payment-success",
    meta: {
      title: "Payment success",
      authRequired: false,
    },
    component: () => import("../views/payments/Success.vue"),
  },

  //Management
  {
    path: "/edit/podcast/:name",
    name: "edit-podcast",
    meta: {
      title: "Edit podcast",
      authRequired: true,
    },
    component: () => import("../views/management/podcast-edit/Podcast.vue"),
  },
  {
    path: "/edit/episode/:id",
    name: "edit-episode",
    meta: {
      title: "Edit episode",
      authRequired: true,
    },
    component: () => import("../views/management/episode-edit/Episode.vue"),
  },
  {
    path: "/create/episode/:name",
    name: "create-episode",
    meta: {
      title: "Create episode",
      authRequired: true,
    },
    component: () => import("../views/management/episode-create/Episode.vue"),
  },
  {
    path: "/live/:id",
    name: "live-create",
    meta: {
      title: "Live Room",
      authRequired: true,
    },
    component: () => import("../views/live/Create.vue"),
  },
  {
    path: "/live/:id/:session",
    name: "live-recording",
    meta: {
      title: "Live Room",
      authRequired: true,
    },
    component: () => import("../views/live/Member.vue"),
  },
  {
    path: "/invite/:id/:session",
    name: "live-invite-recording",
    meta: {
      title: "Live Room",
      authRequired: true,
    },
    component: () => import("../views/live/Invite.vue"),
  },
  {
    path: "/stream/:id/:session",
    name: "live-stream",
    meta: {
      title: "Live Stream",
      authRequired: false,
    },
    component: () => import("../views/live/Stream.vue"),
  },

  //Auth, account
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
      authRequired: false,
    },
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/auth/signout",
    name: "signout",
    meta: {
      title: "SignOut",
      authRequired: true,
    },
    component: () => import("../views/auth/SignOut.vue"),
  },

  {
    path: "/auth-success",
    name: "authSuccess",
    meta: {
      title: "Auth success",
      authRequired: true,
    },
    component: () => import("../views/auth/Success.vue"),
  },

  // Account
  {
    path: "/account",
    name: "account",
    meta: {
      title: "Account",
      authRequired: true,
    },
    component: () => import("../views/account/main/Account.vue"),
  },
  {
    path: "/account/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/account/dashboard/Dashboard.vue"),
  },
  {
    path: "/account/invites",
    name: "invites",
    meta: {
      title: "Invites",
      authRequired: true,
    },
    component: () => import("../views/account/invites/Invites.vue"),
  },
  {
    path: "/account/favourites",
    name: "favourites",
    meta: {
      title: "Favourites",
      authRequired: true,
    },
    component: () => import("../views/account/favourites/Favourites.vue"),
  },

  //Errors
  {
    path: "/404",
    name: "Error404",
    meta: {
      title: "Error 404",
      authRequired: false,
    },
    component: () => import("../views/errors/Error404.vue"),
  },
  {
    path: "/500",
    name: "Error500",
    meta: {
      title: "Error 500",
      authRequired: false,
    },
    component: () => import("../views/errors/Error500.vue"),
  },
  {
    path: "/errors/payment-error",
    name: "PaymentError",
    meta: {
      title: "Payment Error",
      authRequired: false,
    },
    component: () => import("../views/errors/Payment.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: {
      title: "404 Page Not Found",
    },
    component: () => import("../views/errors/Error404.vue"),
  },
];